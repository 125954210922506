import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translations from './translations.json';

i18n
  .use(initReactI18next)
  .init({
    resources: translations,
    lng: 'no',
    returnNull: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
  .then();

export default i18n;
