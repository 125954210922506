import { useLocation } from 'react-router-dom';

import { User } from '../api/queries/user/domain';

import '../utils/i18n';

import { SuspenseSpinner } from '../components/common/Spinner';
import SoliboHomePage from '../components/landingpage/SoliboHomePage';

const PublicRoutes = ({ user }: { user?: User }) => {
  const location = useLocation();
  if (location.pathname.includes('cognito')) return <SuspenseSpinner />;
  if (location.pathname.includes('secure')) return null;

  return <SoliboHomePage user={user} />;
};

export default PublicRoutes;
