import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';
import ProgressiveImage from 'react-progressive-graceful-image';

import Placeholder48 from '../../images/48w/placeholder.webp';
import Placeholder320 from '../../images/320w/placeholder.webp';
import Placeholder640 from '../../images/640w/placeholder.webp';
import Placeholder980 from '../../images/980w/placeholder.webp';

// noinspection JSUnusedLocalSymbols
const ErrorBoundaryView = ({ resetError }: { resetError: () => void }) => (
  <div className={`flex h-full flex-col items-center justify-center sm:items-end`}>
    <ProgressiveImage
      src={Placeholder320}
      srcSetData={{
        srcSet: `${Placeholder320} 320w, ${Placeholder640} 640w, ${Placeholder980} 980w`,
        sizes: '(min-width: 600px) 320w, (min-width: 800px) 640w, 980w',
      }}
      placeholder={Placeholder48}
    >
      {(src, loading, srcSetData) => (
        <img
          className={`h-full w-full object-fill ${loading ? 'blur' : ''}`}
          src={src}
          srcSet={srcSetData?.srcSet}
          sizes={srcSetData?.sizes}
          alt='banner'
        />
      )}
    </ProgressiveImage>
    <div className='absolute z-10 flex flex-col items-center p-6 sm:-mt-20 sm:w-2/3'>
      <div className='mx-auto mb-6 flex h-40 w-40 items-center justify-center rounded-full bg-gray-200'>
        <ExclamationCircleIcon className='h-32 w-32 text-red-400' aria-hidden={true} />
      </div>
      <p className='mb-3 text-center text-3xl font-semibold text-gray-600 sm:text-gray-700'>
        Beklager, noe gikk galt.
      </p>
    </div>
  </div>
);

export default ErrorBoundaryView;
