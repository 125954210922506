import classNames from '../../utils/classNames';

export const Spinner = ({
  className = '',
  bgGray = '50',
}: {
  className?: string;
  bgGray?: string;
}) => (
  <div className={classNames(`bg-gray-${bgGray}`, 'flex flex-col justify-center')}>
    <div className='flex items-center justify-center'>
      <div
        className={classNames(
          className,
          'h-4 w-4 animate-spin rounded-full border-y-2 border-indigo-600'
        )}
      />
    </div>
  </div>
);

export const SuspenseSpinner = () => (
  <div className='flex h-screen w-screen flex-col justify-center bg-gray-50'>
    <div className='flex items-center justify-center'>
      <div className='h-4 w-4 animate-spin rounded-full border-y-2 border-indigo-600' />
    </div>
  </div>
);

export const ContentSpinner = ({ bgColor }: { bgColor?: string }) => (
  <div
    className={classNames(
      bgColor ? `bg-${bgColor}` : 'bg-gray-50',
      'flex h-full w-full flex-col justify-center'
    )}
  >
    <div className='flex items-center justify-center'>
      <div className='h-4 w-4 animate-spin rounded-full border-y-2 border-indigo-600' />
    </div>
  </div>
);

export const ButtonSpinner = (props: any) => (
  <div className='flex h-5 w-5 flex-col justify-center'>
    <div className='flex items-center justify-center'>
      <div
        data-cy={'button-spinner'}
        className={`h-4 w-4 animate-spin rounded-full border-y-2 ${
          props.secondary ? 'border-gray-700' : 'border-white'
        }`}
      />
    </div>
  </div>
);
