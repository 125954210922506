import { Popover, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  BriefcaseIcon,
  CalculatorIcon,
  ChartBarIcon,
  ComputerDesktopIcon,
  LifebuoyIcon,
  MegaphoneIcon,
  PhoneIcon,
  ShareIcon,
  Squares2X2Icon,
  UserGroupIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { Fragment, lazy, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { User } from '../../api/queries/user/domain';

import config from '../../config';
import SoliboLiggendeLogo from '../../images/svg/solibo-home-farger-liggende.svg';
import SoliboLogo from '../../images/svg/solibo-home-farger.svg';
import lazyRetry from '../../utils/lazyRetry';

const TrialSignup = lazy(() => lazyRetry(() => import('../common/TrialSignup')));

const features = [
  {
    name: 'Styrearbeid',
    href: '/styrearbeid',
    description:
      'Digitale styremøter med elektronisk signering av protokoll. Styresaker og oppgaver.',
    icon: UserGroupIcon,
  },
  {
    name: 'Hjemmeside',
    href: '/hjemmeside',
    description: 'Egen hjemmeside til sameiet eller borettslaget følger med.',
    icon: ComputerDesktopIcon,
  },
  {
    name: 'Kommunikasjon med beboere',
    href: '/kommunikasjon',
    description: 'Effektiv kommunikasjon til beboere med valgfri varsling på epost og SMS.',
    icon: MegaphoneIcon,
  },
  {
    name: 'Økonomi',
    href: '/okonomi',
    description: 'En unik løsning for fakturagodkjenning som effektiviserer styrearbeidet.',
    icon: CalculatorIcon,
  },
  {
    name: 'Se flere løsninger',
    href: '/alle',
    description:
      'Oversikt over beboere og leverandører, samtaler, styrenotater, dokumenter med mer.',
    icon: ChartBarIcon,
  },
];
const callsToAction = [{ name: 'Kontakt salg', to: 'kontakt', icon: PhoneIcon }];
const resources = [
  {
    name: 'Integrasjoner',
    description: 'Tripletex, DNB, og mer kommer.',
    href: '/integrasjoner',
    icon: Squares2X2Icon,
  },
  {
    name: 'Partnerskap',
    description:
      'Ønsker du å tilby markedets beste styreportal til dine kunder og samtidig effektivisere interne prosesser?',
    href: '/partnerskap',
    icon: ShareIcon,
  },
  {
    name: 'Jobb',
    description: 'Se ledige stillinger. Vi er på utkikk etter dyktige kolleger.',
    href: '/jobb',
    icon: BriefcaseIcon,
  },
  {
    name: 'Hjelpesenter',
    description: 'Få svar på vanlige spørsmål eller kontakt support.',
    href: '/hjelpesenter',
    icon: LifebuoyIcon,
  },
];
const recentPosts = [
  {
    id: 1,
    name: '7 steg for effektive styremøter',
    href: 'blogg/7-steg-for-effektive-styremoter',
  },
];

export const Header = ({ user }: { user?: User }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {modalVisible && (
        <TrialSignup modalVisible={modalVisible} setModalVisible={setModalVisible} />
      )}
      <Popover className='relative z-10 bg-white shadow'>
        <div className='mx-auto max-w-7xl px-4 sm:px-6'>
          <div className='flex items-center justify-between md:justify-start md:space-x-10'>
            <div className='flex justify-start lg:flex-1'>
              <Link to='/' className='focus:focus-ring shrink-0'>
                <span className='sr-only'>Solibo Home</span>
                <img className='h-28' src={SoliboLogo} alt='Solibo Home logo' />
              </Link>
            </div>
            <div className='flex space-x-3 md:hidden'>
              {!user || user?.username === 'ANONYMOUS' ? (
                <Link
                  to={`login`}
                  className='focus:focus-ring text-center text-base font-medium text-green-600 hover:text-green-500'
                  data-cy='login'
                >
                  {t('registration.signIn')}
                </Link>
              ) : (
                <Link
                  to={`/${config.securePrefix}/styreportal`}
                  className='focus:focus-ring text-center text-base font-medium text-green-600 hover:text-green-500'
                  data-cy='login'
                >
                  Gå til Styreportalen
                </Link>
              )}
              <div className='-my-2 -mr-2 md:hidden'>
                <Popover.Button className='focus:focus-ring inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-700'>
                  <span className='sr-only'>Åpne meny</span>
                  <Bars3Icon className='h-6 w-6' aria-hidden='true' />
                </Popover.Button>
              </div>
            </div>
            <Popover.Group as='nav' className='hidden space-x-10 md:flex'>
              <Popover className='relative'>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? 'text-gray-900' : 'text-gray-700',
                        'group bg-white inline-flex items-center text-base font-medium hover:text-gray-900 focus:focus-ring'
                      )}
                    >
                      <span>Funksjoner</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? 'text-gray-600' : 'text-gray-400',
                          'ml-2 h-5 w-5 group-hover:text-gray-700'
                        )}
                        aria-hidden='true'
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-200'
                      enterFrom='opacity-0 translate-y-1'
                      enterTo='opacity-100 translate-y-0'
                    >
                      {/* Popover for funksjoner */}
                      <Popover.Panel className='absolute z-30 -ml-4 mt-3 w-screen max-w-md px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2'>
                        {({ close }) => (
                          <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5'>
                            <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8'>
                              {features.map((item) => (
                                <Link
                                  key={item.name}
                                  to={item.href}
                                  className='-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50'
                                  onClick={() => close()}
                                >
                                  <item.icon
                                    className='h-6 w-6 shrink-0 text-emerald-600'
                                    aria-hidden='true'
                                  />
                                  <div className='ml-4'>
                                    <p className='text-base font-semibold'>{item.name}</p>
                                    <p className='mt-1 text-gray-700'>{item.description}</p>
                                  </div>
                                </Link>
                              ))}
                            </div>
                            <div className='space-y-6 bg-gray-50 p-5 sm:flex sm:space-x-10 sm:space-y-0 sm:px-8'>
                              {callsToAction.map((item) => (
                                <div key={item.name} className='flow-root'>
                                  <Link
                                    to={item.to}
                                    className='-m-3 flex items-center rounded-md p-3 text-base font-semibold hover:bg-gray-100'
                                    onClick={() => close()}
                                  >
                                    <item.icon
                                      className='h-6 w-6 shrink-0 text-gray-400'
                                      aria-hidden='true'
                                    />
                                    <span className='ml-3'>{item.name}</span>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Link
                data-cy={'priser-link'}
                to='priser'
                className='focus:focus-ring text-base font-medium text-gray-700 hover:text-gray-900'
              >
                Priser
              </Link>
              <Popover className='relative'>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? 'text-gray-900' : 'text-gray-700',
                        'group bg-white inline-flex items-center text-base font-medium hover:text-gray-900 focus:focus-ring'
                      )}
                    >
                      <span>Mer</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? 'text-gray-600' : 'text-gray-400',
                          'ml-2 h-5 w-5 group-hover:text-gray-700'
                        )}
                        aria-hidden='true'
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-200'
                      enterFrom='opacity-0 translate-y-1'
                      enterTo='opacity-100 translate-y-0'
                    >
                      <Popover.Panel className='absolute left-1/2 z-30 mt-3 w-screen max-w-md -translate-x-1/2 px-2 sm:px-0'>
                        {({ close }) => (
                          <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5'>
                            <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8'>
                              {resources.map((item) => (
                                <Link
                                  key={item.name}
                                  to={item.href}
                                  className='-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50'
                                  onClick={() => close()}
                                >
                                  <item.icon
                                    className='h-6 w-6 shrink-0 text-emerald-600'
                                    aria-hidden='true'
                                  />
                                  <div className='ml-4'>
                                    <p className='text-base font-semibold'>{item.name}</p>
                                    <p className='mt-1 text-gray-700'>{item.description}</p>
                                  </div>
                                </Link>
                              ))}
                            </div>
                            <div className='bg-gray-50 p-5 sm:p-8'>
                              <div>
                                <h3 className='font-medium uppercase tracking-wide text-gray-700'>
                                  Nylige innlegg
                                </h3>
                                <ul className='mt-4 space-y-4'>
                                  {recentPosts.map((item) => (
                                    <li key={item.id} className='truncate text-base'>
                                      <Link
                                        to={item.href}
                                        className='font-semibold hover:text-gray-700'
                                        onClick={() => close()}
                                      >
                                        {item.name}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div className='mt-5'>
                                <Link
                                  to='blogg'
                                  className='font-medium text-green-600 hover:text-green-500'
                                  onClick={() => close()}
                                >
                                  {' '}
                                  Se alle innlegg <span aria-hidden='true'>&rarr;</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
            <div className='hidden items-center justify-end md:flex md:flex-1 lg:w-0'>
              {!user || user?.username === 'ANONYMOUS' ? (
                <Link
                  to={`login`}
                  className='focus:focus-ring text-center text-base font-medium text-green-600 hover:text-green-500'
                  data-cy='login'
                >
                  {t('registration.signIn')}
                </Link>
              ) : (
                <Link
                  to={`/${config.securePrefix}/styreportal`}
                  className='focus:focus-ring text-center text-base font-medium text-green-600 hover:text-green-500'
                  data-cy='login'
                >
                  Gå til Styreportalen
                </Link>
              )}
              <button
                data-cy={'header-try-free-button'}
                onClick={() => setModalVisible(true)}
                className='focus:focus-border ml-8 inline-flex cursor-pointer items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700'
              >
                Bruk gratis
              </button>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter='duration-200 ease-out'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
        >
          <Popover.Panel
            focus
            className='absolute inset-x-0 top-0 z-30 origin-top-right p-2 transition md:hidden'
          >
            {({ close }) => (
              <div className='divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black/5'>
                <div className='px-5 pb-6 pt-5'>
                  <div className='flex items-center justify-between'>
                    <div>
                      <img className='h-8 w-auto' src={SoliboLiggendeLogo} alt='Solibo Home logo' />
                    </div>
                    <div className='-mr-2'>
                      <button
                        onClick={() => close()}
                        className='focus:focus-ring inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-700'
                      >
                        <span className='sr-only'>Lukk meny</span>
                        <XCircleIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>
                  </div>
                  <div className='mt-6'>
                    <nav className='grid gap-y-8'>
                      {features.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className='focus:focus-ring -m-3 flex items-center rounded-md p-3 hover:bg-gray-50'
                          onClick={() => close()}
                        >
                          <item.icon
                            className='h-6 w-6 shrink-0 text-emerald-600'
                            aria-hidden='true'
                          />
                          <span className='ml-3 text-base font-semibold'>{item.name}</span>
                        </Link>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className='space-y-6 px-5 py-6'>
                  <button
                    onClick={() => setModalVisible(true)}
                    className='focus:focus-ring flex w-full items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-emerald-700'
                  >
                    Bruk gratis
                  </button>
                  <div className='grid grid-cols-2 gap-x-8 gap-y-4'>
                    <Link
                      to='priser'
                      onClick={() => close()}
                      className='focus:focus-ring text-base font-semibold hover:text-gray-700'
                    >
                      Priser
                    </Link>

                    <Link
                      to='/kontakt'
                      onClick={() => close()}
                      className='focus:focus-ring text-base font-semibold hover:text-gray-700'
                    >
                      Kontakt salg
                    </Link>
                    {resources.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className='focus:focus-ring text-base font-semibold hover:text-gray-700'
                        onClick={() => close()}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
};
