import { CheckIcon, InboxIcon, SparklesIcon } from '@heroicons/react/24/outline';
import React from 'react';
import ProgressiveImage from 'react-progressive-graceful-image';
import { Link } from 'react-router-dom';

import Meldinger48 from '../../images/48w/meldinger.webp';
import Oppslagstavle48 from '../../images/48w/oppslagstavle.webp';
import Meldinger320 from '../../images/320w/meldinger.webp';
import Oppslagstavle320 from '../../images/320w/oppslagstavle.webp';
import Meldinger640 from '../../images/640w/meldinger.webp';
import Oppslagstavle640 from '../../images/640w/oppslagstavle.webp';
import Meldinger980 from '../../images/980w/meldinger.webp';
import Oppslagstavle980 from '../../images/980w/oppslagstavle.webp';

const features = [
  {
    name: 'Styremøter',
    description:
      'Gjennomfør møter digitalt og signér protokollen med mobil eller pc så snart møtet er ferdig.',
  },
  {
    name: 'Styresaker',
    description: 'Bygg opp saker over tid, for så å behandle de på styremøter.',
  },
  {
    name: 'Hjemmeside til boligselskapet',
    description:
      'Hold enkelt alle beboere oppdatert på hva som skjer i borettslaget eller sameiet.',
  },
  {
    name: 'Kommunikasjon med beboere',
    description:
      'Beboerne kan lett ta kontakt med styret, og styret har alle samtaler samlet på et sted.',
  },
  {
    name: 'Oversikt over eiere og leietakere',
    description: 'En samlet oversikt over kontaktinformasjon til alle beboere.',
  },
  {
    name: 'Leverandøroppfølging',
    description:
      'En fullstendig oversikt over alle leverandører, og kontaktpersoner knyttet til leverandørene.',
  },
  {
    name: 'Fakturaoversikt',
    description: 'Oversikt over alle fakturaer. Du attesterer fakturaene - vi fikser resten.',
  },
  {
    name: 'Utleggshåndtering',
    description:
      'Last opp kvittering, få den attestert av en i styret - vipps så får du pengene tilbake på konto.',
  },
];

export const FeaturesSummary = () => {
  return (
    <div className='bg-white'>
      <div className='mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:px-8 lg:py-24'>
        <div>
          <h2 className='text-base font-semibold uppercase tracking-wide text-indigo-600'>
            Alt-i-ett styreportal
          </h2>
          <p className='mt-2 text-3xl font-extrabold'>Alt på ett sted</p>
          <p className='mt-4 text-lg text-gray-700'>
            Vi jobber for at styret skal kunne gjøre alt sitt arbeid på én plattform.
          </p>
        </div>
        <div className='mt-12 lg:col-span-2 lg:mt-0'>
          <dl className='space-y-10 sm:grid sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-4 sm:gap-x-6 sm:gap-y-10 sm:space-y-0 lg:gap-x-8'>
            {features.map((feature) => (
              <div key={feature.name} className='relative'>
                <dt>
                  <CheckIcon className='absolute h-6 w-6 text-emerald-500' aria-hidden='true' />
                  <p className='ml-9 text-lg font-semibold leading-6'>{feature.name}</p>
                </dt>
                <dd className='ml-9 mt-2 text-base text-gray-700'>{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export const FeaturesTopTwo = () => {
  return (
    <div className='relative w-full overflow-hidden bg-white pb-32 pt-16'>
      <div className='w-full lg:mx-auto lg:grid lg:grid-flow-col-dense lg:grid-cols-12 lg:gap-24 lg:px-8'>
        <div className='mx-auto max-w-xl px-4 sm:px-6 lg:col-span-4 lg:col-start-7 lg:mx-0 lg:max-w-none lg:px-0 lg:py-32'>
          <div>
            <div>
              <span className='flex h-12 w-12 items-center justify-center rounded-md bg-indigo-600'>
                <SparklesIcon className='h-6 w-6 text-white' aria-hidden='true' />
              </span>
            </div>
            <div className='mt-6'>
              <h2 className='text-3xl font-extrabold tracking-tight'>
                Hjemmeside integrert i løsningen
              </h2>
              <p className='mt-4 w-8/12 text-lg text-gray-700'>
                Sentralt i løsningen er en hjemmeside til sameiet eller borettslaget. Styret
                redigerer enkelt oppslagene direkte på hjemmesiden. Under kontaktfanen kan beboerne
                i sameiet ta kontakt med styret. Styret får da meldingen rett inn i styreportalen.
              </p>
              <div className='mt-6'>
                <Link
                  to='/hjemmeside'
                  className='focus:focus-ring inline-flex rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700'
                >
                  Les mer
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-12 sm:mt-16 lg:col-span-5 lg:col-start-2 lg:mt-0'>
          <div className='-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:w-full lg:px-0'>
            <ProgressiveImage
              src={Oppslagstavle320}
              srcSetData={{
                srcSet: `${Oppslagstavle320} 320w, ${Oppslagstavle640} 640w, ${Oppslagstavle980} 980w`,
                sizes: '(min-width: 600px) 320w, (min-width: 800px) 640w, 980w',
              }}
              placeholder={Oppslagstavle48}
            >
              {(src, loading, srcSetData) => (
                <img
                  className={`w-full rounded-xl shadow-xl ring-1 ring-black/5 lg:absolute lg:right-0 lg:h-full lg:w-full ${
                    loading ? 'blur' : ''
                  }`}
                  src={src}
                  srcSet={srcSetData?.srcSet}
                  sizes={srcSetData?.sizes}
                  alt='Hjemmeside til et boligselskap'
                />
              )}
            </ProgressiveImage>
          </div>
        </div>
      </div>
      <div className='mt-24'></div>
      <div className='w-full lg:mx-auto lg:grid lg:grid-flow-col-dense lg:grid-cols-12 lg:gap-24 lg:px-8'>
        <div className='mx-auto max-w-xl px-4 sm:px-6 lg:col-span-3 lg:col-start-4 lg:mx-0 lg:max-w-none lg:px-0 lg:py-16'>
          <div>
            <div>
              <span className='flex h-12 w-12 items-center justify-center rounded-md bg-indigo-600'>
                <InboxIcon className='h-6 w-6 text-white' aria-hidden='true' />
              </span>
            </div>
            <div className='mt-6'>
              <h2 className='text-3xl font-extrabold tracking-tight'>Effektiv kommunikasjon</h2>
              <p className='mt-4 text-lg text-gray-700'>
                Styret kan enkelt kommunisere med eiere og leietakere, så vel som leverandører.
                Styret kan også enkelt kommunisere med hverandre. All kommunikasjon gjennom
                styreportalen blir samlet på ett sted, og borettslagets historikk blir dermed ikke
                spredt over flere personlige e-postklienter tilhørende nåværende og tidligere
                styremedlemmer.
              </p>
              <div className='mt-6'>
                <Link
                  to='/kommunikasjon'
                  className='focus:focus-ring inline-flex rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700'
                >
                  Les mer
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-12 sm:mt-16 lg:col-span-5 lg:col-start-7 lg:mt-0'>
          <div className='-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:w-full lg:px-0'>
            <ProgressiveImage
              src={Meldinger320}
              srcSetData={{
                srcSet: `${Meldinger320} 320w, ${Meldinger640} 640w, ${Meldinger980} 980w`,
                sizes: '(min-width: 600px) 320w, (min-width: 800px) 640w, 980w',
              }}
              placeholder={Meldinger48}
            >
              {(src, loading, srcSetData) => (
                <img
                  className={`w-full rounded-xl shadow-xl ring-1 ring-black/5 lg:absolute lg:left-0 lg:h-full ${
                    loading ? 'blur' : ''
                  }`}
                  src={src}
                  srcSet={srcSetData?.srcSet}
                  sizes={srcSetData?.sizes}
                  alt='Brukergrensesnitt for samtalemodulen'
                />
              )}
            </ProgressiveImage>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FeatureTextRight: React.FC<{
  headline: String;
  description: String;
  buttonText: String;
  href: string;
  quote?: String;
  quoteOwner?: String;
  quoteOwnerPhoto?: string;
  img: {
    w320: string;
    w640: string;
    w980: string;
  };
  altText: string;
}> = ({
  headline,
  description,
  buttonText,
  href,
  quote,
  quoteOwner,
  quoteOwnerPhoto,
  img,
  altText,
}) => {
  return (
    <div className='overflow-hidden lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8'>
      <div className='mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:px-0 lg:py-32'>
        <div className='mt-6'>
          <h2 className='text-3xl font-extrabold tracking-tight'>{headline}</h2>
          <p className='mt-4 text-lg text-gray-700'>{description}</p>
          <div className='mt-6'>
            <Link
              to={href}
              className='inline-flex rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700'
            >
              {buttonText}
            </Link>
          </div>
        </div>
        {quote && (
          <div className='mt-8 border-t border-gray-200 pt-6'>
            <blockquote>
              <div>
                <p className='text-base text-gray-700'>&ldquo;{quote}&rdquo;</p>
              </div>
              <footer className='mt-3'>
                <div className='flex items-center space-x-3'>
                  {quoteOwnerPhoto && (
                    <div className='shrink-0'>
                      <img className='h-6 w-6 rounded-full' src={quoteOwnerPhoto} alt='' />
                    </div>
                  )}
                  <div className='text-base font-medium'>{quoteOwner}</div>
                </div>
              </footer>
            </blockquote>
          </div>
        )}
      </div>
      <div className='mt-12 sm:mt-16 lg:col-start-1 lg:mt-0'>
        <div className='-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0'>
          <img
            loading='lazy'
            className='w-full rounded-xl shadow-xl ring-1 ring-black/5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none'
            alt={altText}
            src={img.w320}
            sizes='(min-width: 600px) 320w, (min-width: 800px) 640w, 980w'
            srcSet={`${img.w320} 320w, ${img.w640} 640w, ${img.w980} 980w`}
          />
        </div>
      </div>
    </div>
  );
};

export const FeatureTextLeft: React.FC<{
  headline: String;
  description: String;
  buttonText: String;
  href: string;
  quote?: String;
  quoteOwner?: String;
  quoteOwnerPhoto?: string;
  img: {
    w320: string;
    w640: string;
    w980: string;
  };
  altText: string;
}> = ({
  headline,
  description,
  buttonText,
  href,
  quote,
  quoteOwner,
  quoteOwnerPhoto,
  img,
  altText,
}) => {
  return (
    <div className='overflow-hidden lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8'>
      <div className='mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:px-0 lg:py-16'>
        <div>
          <div className='mt-6'>
            <h2 className='text-3xl font-extrabold tracking-tight'>{headline}</h2>
            <p className='mt-4 text-lg text-gray-700'>{description}</p>
            <div className='mt-6'>
              <Link
                to={href}
                className='inline-flex rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700'
              >
                {buttonText}
              </Link>
            </div>
          </div>
          {quote && (
            <div className='mt-8 border-t border-gray-200 pt-6'>
              <blockquote>
                <div>
                  <p className='text-base text-gray-700'>&ldquo;{quote}&rdquo;</p>
                </div>
                <footer className='mt-3'>
                  <div className='flex items-center space-x-3'>
                    {quoteOwnerPhoto && (
                      <div className='shrink-0'>
                        <img className='h-6 w-6 rounded-full' src={quoteOwnerPhoto} alt='' />
                      </div>
                    )}
                    <div className='text-base font-medium'>{quoteOwner}</div>
                  </div>
                </footer>
              </blockquote>
            </div>
          )}
        </div>
      </div>
      <div className='mt-12 sm:mt-16 lg:mt-0'>
        <div className='-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0'>
          <img
            loading='lazy'
            className='w-full rounded-xl shadow-xl ring-1 ring-black/5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none'
            alt={altText}
            src={img.w320}
            sizes='(min-width: 600px) 320w, (min-width: 800px) 640w, 980w'
            srcSet={`${img.w320} 320w, ${img.w640} 640w, ${img.w980} 980w`}
          />
        </div>
      </div>
    </div>
  );
};
