import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotifications } from '../../hooks/useNotifications';
import { ButtonSpinner } from '../common/Spinner';
import { subscribe, SubscriptionType } from '../common/SubscribeEmail';

export const NewsletterWhiteBG = () => {
  const { t } = useTranslation();
  const { genericError, success } = useNotifications();
  const [email, setEmail] = useState<string>('');
  const [boligselskap, setBoligselskap] = useState<string>('');
  const [sending, setSending] = useState(false);
  const [sendingState, setSendingState] = useState<JSX.Element>(<p>Meld på</p>);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    subscribeNewsletter(formData);
  };
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.name === 'email') {
      setEmail(e.currentTarget.value);
    } else if (e.currentTarget.name === 'boligselskap') {
      setBoligselskap(e.currentTarget.value);
    }
  };

  const subscribeNewsletter = (formData: FormData) => {
    setSending(true);
    subscribe({
      subscriptionType: SubscriptionType.NEWSLETTER,
      formData: formData,
    })
      .then((_) => {
        setEmail('');
        setBoligselskap('');
        success(t('marketing.newsletter.posted'));
      })
      .catch((e) => {
        genericError(e);
      })
      .finally(() => {
        setSending(false);
      });
  };

  useEffect(() => {
    if (sending) {
      setSendingState(<ButtonSpinner />);
    } else {
      setSendingState(<p>Meld på</p>);
    }
  }, [sending]);

  return (
    <div className='bg-white'>
      <div className='mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16'>
        <div>
          <h2 className='inline text-3xl font-extrabold tracking-tight sm:block sm:text-4xl'>
            Hold deg oppdatert.{' '}
          </h2>
          <p className='inline text-3xl font-extrabold tracking-tight text-indigo-600 sm:block sm:text-4xl'>
            Meld deg på vårt nyhetsbrev.
          </p>
        </div>
        <div className='max-h-modal inline-block w-full overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 sm:align-middle'>
          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <div className='mt-3 sm:mt-5'>
                  <div className='mt-2'>
                    <label
                      htmlFor='email-address'
                      className={`mt-4 block font-medium text-gray-700`}
                    />
                    <input
                      type='email'
                      name='email'
                      data-cy={'newsletter-email-input'}
                      id='email-address'
                      autoComplete='email'
                      required
                      value={email}
                      className='focus:focus-border w-full rounded-md border-gray-300 px-5 py-3 placeholder:text-gray-700'
                      placeholder='Skriv inn epostadresse'
                      onChange={handleChange}
                    />
                    <label
                      htmlFor='boligselskap'
                      className={`mt-4 block font-medium text-gray-700`}
                    />
                    <input
                      id='boligselskap'
                      name='boligselskap'
                      data-cy={'newsletter-boligselskap-input'}
                      type='text'
                      value={boligselskap}
                      className='focus:focus-border w-full rounded-md border-gray-300 px-5 py-3 placeholder:text-gray-700'
                      placeholder='Skriv gjerne inn navnet på ditt boligselskap'
                      onChange={handleChange}
                    />
                    <div className={`mt-4 block font-medium text-gray-700`}>
                      <button
                        type='submit'
                        data-cy={'newsletter-button'}
                        className='focus:focus-ring flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700'
                      >
                        {sendingState}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
